@use '@angular/material' as mat;
@use 'themed-parts';

// DARK THEME (default)
// ref: https://material.angular.io/guide/theming

$dark-theme: mat.define-theme((
  color: (
    theme-type: dark,
    primary: mat.$azure-palette
  ),
  typography: (
    brand-family: "Rubik, Roboto, Arial, Helvetica, sans-serif",
  ),
  density: (
    scale: 0
  )));

:root { // default theme (load once)
  @include mat.all-component-themes($dark-theme);
}

// theme (applied to body)
.dark-theme {

  // not using mat.all-component-colors()
  @include themed-parts.theme($dark-theme);

  // COLOR OVERRIDES only

  // typography
  a:hover {
    color: mat.get-theme-color($dark-theme, neutral, 70);
  }

  // toolbar
  .main-toolbar {

    h1 {
      color: rgba(230, 199, 76, 0.75);

      a:hover {
        color: rgba(230, 199, 76, 0.9);
      }
    }

    h2 {
      color: mat.get-theme-color($dark-theme, neutral, 60);

      a:hover {
        color: mat.get-theme-color($dark-theme, neutral, 70);
      }
    }
  }
}
