@use '@angular/material' as mat;
@use 'themed-parts';

// LIGHT THEME
// ref: https://material.angular.io/guide/theming

$light-theme: mat.define-theme((
  color: (
    theme-type: light,
    primary: mat.$azure-palette

    // typography and density are inherited
    // from the default dark theme
  )));

// theme (applied to body)
.light-theme {

  @include mat.all-component-colors($light-theme);
  @include themed-parts.theme($light-theme);

  // COLOR OVERRIDES only

  // typography
  a:hover {
    color: mat.get-theme-color($light-theme, neutral, 30);
  }

  // toolbar
  .main-toolbar {

    h1 {
      color: mat.get-theme-color($light-theme, neutral, 40);

      a:hover {
        color: mat.get-theme-color($light-theme, neutral, 10);
      }
    }

    h2 {
      color: mat.get-theme-color($light-theme, neutral, 50);

      a:hover {
        color: mat.get-theme-color($light-theme, neutral, 20);
      }
    }
  }
}
