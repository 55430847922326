@use 'sass:map';
@use '@angular/material' as mat;

// SCROLLBAR THEME
@mixin theme($theme) {

  @media screen and (width >=800px) {

    /* width */
    ::-webkit-scrollbar {
      width: 10px;
    }

    /* track */
    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 3px mat.get-theme-color($theme, neutral, 40);
      border-radius: 2px;
    }

    /* handle */
    ::-webkit-scrollbar-thumb {
      background: mat.get-theme-color($theme, neutral, 40);
      border-radius: 2px;
    }

    /* handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: mat.get-theme_color($theme, neutral, 30);
    }
  }
}
